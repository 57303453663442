import React from "react";
import "../assets/css/Navbar.css";
import { Navbar, Nav, Container, Image, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavbarComponent = (props) => {
    const logo = require("../assets/images/logo-text-white.png");

    return (
        <div className="nav-border">
            <Navbar collapseOnSelect expand="lg">
                <Container fluid className="custom-toggler">
                    <Navbar.Brand href="/">
                        <Image
                            className="d-inline-block align-top"
                            src={logo}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
                        <Nav
                            defaultActiveKey="/"
                            className="ml-auto center-img"
                        >
                            <NavLink
                                className="nav-link center"
                                exact
                                activeClassName="active"
                                to="/tentang"
                            >
                                Tentang Kami
                            </NavLink>
                            <NavLink
                                className="nav-link"
                                exact
                                activeClassName="active"
                                to="/layanan"
                            >
                                Layanan
                            </NavLink>
                            <NavLink
                                className="nav-link"
                                exact
                                activeClassName="active"
                                to="/blog"
                            >
                                Blog
                            </NavLink>
                            <NavLink
                                className="nav-link"
                                exact
                                activeClassName="active"
                                to="/kontak"
                            >
                                Kontak
                            </NavLink>
                        </Nav>
                        <div className="d-md-none">
                            <Button
                                className="wa-btn"
                                target="_blank"
                                href="https://wa.me/628176389206"
                            >
                                (+62) 817-6389-206
                            </Button>
                        </div>
                    </Navbar.Collapse>
                    <div className="nav-right">
                        <Button
                            className="wa-btn"
                            target="_blank"
                            href="https://wa.me/628176389206"
                        >
                            (+62) 817-6389-206
                        </Button>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavbarComponent;
