import React from "react";
import "../assets/css/Service.css";
import { Container, Col, Image, Row } from "react-bootstrap";

export default class ServiceComponent extends React.Component {
	render() {
		return (
			<div className="service">
				<div className="service-first">
					<Container className="s-container">
						<Col lg="7" md="12" className="block-centered text-align-center">
							<h1 className="heading-one">Layanan</h1>
							<ul className="ts-breadcrumb">
								<li>
									<a href="/">Beranda</a>
								</li>
								<li className="active">Layanan</li>
							</ul>
						</Col>
					</Container>
				</div>
				<div className="service-second margin-bottom">
					<div className="section">
						<Container className="s-container margin-bottom-2">
							<Col lg="6" md="12" className="block-centered text-align-center">
								<h2>Konveksi dan Sablon</h2>
								<div>
									Menawarkan jasa sablon dan konveksi berkualitas dengan
									pengalaman bertahun - tahun. Memiliki mesin produksi terbaik
									dan juga pekerja profesional dibidangnya. Memberikan jaminan
									hasil terbaik bagi pelanggan.
								</div>
							</Col>
						</Container>
						<Container className="s-container">
							<Col lg="6" md="12">
								<div className="fh-align-top">
									<Image
										className="img-responsive margin-right service-img"
										src={require("../assets/images/services.png")}
									/>
									<div>
										<h4>Sablon Berkualitas</h4>
										<div className="margin-bottom">
											Sablon berkualitas, didukung dengan peralatan terbaik dan
											canggih serta pekerja profesional
										</div>
									</div>
								</div>
							</Col>
							<Col lg="6" md="12">
								<div className="fh-align-top">
									<Image
										className="img-responsive margin-right service-img"
										src={require("../assets/images/services.png")}
									/>
									<div>
										<h4>Konveksi Terbaik</h4>
										<div className="margin-bottom">
											Konveksi berbagai macam produk dengan kualitas dan bahan
											terbaik dalam jumlah besar ataupun kecil
										</div>
									</div>
								</div>
							</Col>
						</Container>
					</div>
				</div>
				<div className="service-third bg-accent">
					<Image
						src={require("../assets/images/layanan-img.jpg")}
						alt=""
						className="st-img img-responsive"
					/>
					<div className="section">
						<Container className="s-container">
							<Col lg="6" md="12">
								<div className="pre-title">Penawaran lain</div>
								<h2>Tukangsablon.net</h2>
								<p>
									Sablon dan konveksi merupakan jasa yang kami tawarkan kepada
									pelanggan. Disamping itu, kami juga memberikan beberapa
									pelayanan lainnya yang tidak kalah berguna.
								</p>
							</Col>
							<Col lg="6" md="12" className="alignself-center">
								<Container className="s-container container-nested is-wrapped">
									<Row>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/pekerja-prefesional.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Pekerja prefesional dibidangnya
											</div>
										</Col>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/mesin-canggih.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Peralatan Modern dan canggih
											</div>
										</Col>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/cepat.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Proses produksi yang cepat
											</div>
										</Col>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/pesan-antar.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Pesanan pelanggan dapat kami antar
											</div>
										</Col>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/berkualitas.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Bahan terbaik dan berkualitas
											</div>
										</Col>
										<Col lg="4" className="margin-bottom-2">
											<div className="fh-align-center margin-bottom">
												<Image
													src={require("../assets/images/harga-bersaing.png")}
													alt=""
													height="64"
													className="margin-right img-responsive"
												/>
											</div>
											<div className="margin-bottom">
												Harga bersaing dengan kompetitor
											</div>
										</Col>
									</Row>
								</Container>
							</Col>
						</Container>
					</div>
				</div>
			</div>
		);
	}
}
