import React from "react";
import "../assets/css/Home.css";
import { Col, Container, Image } from "react-bootstrap";
import { getAPI } from "../utils/Requests";

export default class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            show: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        var url = "post/list?limit=2";
        getAPI(url).then((data) => {
            console.log(data);
            this.setState({ posts: data });
        });
    };

    render() {
        return (
            <div className="home">
                <div className="first-section">
                    <Container className="s-container">
                        <Col
                            lg="7"
                            md="12"
                            className="block-centered text-align-center"
                        >
                            <Image
                                className="img-responsive d-block d-sm-none"
                                src={require("../assets/images/logo.png")}
                            />
                            <h1 className="heading-one">
                                Cari Jasa Sablon? Ya disini, TukangSablon.net
                            </h1>
                            <div className="fs-desc d-none d-sm-block">
                                Memberikan solusi sablon berkualitas dengan
                                bahan terbaik, pengerjaan cepat dan harga yang
                                bersaing. Percayakan kebutuhan sablon anda
                                kepada Tukangsablon.net Hasil dengan kualitas
                                terbaik menanti anda.
                            </div>
                            <a
                                href="tel:628176389206"
                                className="fs-contact-btn block-centered btn-primary"
                            >
                                <span>Hubungi Kami</span>
                            </a>
                        </Col>
                    </Container>
                </div>
                <div className="second-section">
                    <div className="section ss-section margin-bottom">
                        <Container>
                            <Col
                                lg="6"
                                md="12"
                                className="block-centered text-align-center"
                            >
                                <h2>
                                    Tukangsablon.net, mengutamakan kepuasan
                                    pelanggan dan hasil terbaik
                                </h2>
                            </Col>
                        </Container>
                        <Container className="flex-horizontal">
                            <Col lg="4" md="12">
                                <div className="fh-align-center">
                                    <div className="icon-box">
                                        <img
                                            src={require("../assets/images/quality.png")}
                                            alt=""
                                            className="ss-img img-responsive"
                                        />
                                    </div>
                                    <div className="text-box">
                                        <h4>Kualitas Terbaik</h4>
                                        <div className="margin-bottom">
                                            Kualitas terbaik merupakan jaminan
                                            yang kami berikan kepada anda.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="12">
                                <div className="fh-align-center">
                                    <div className="icon-box">
                                        <img
                                            src={require("../assets/images/price.png")}
                                            alt=""
                                            className="ss-img img-responsive"
                                        />
                                    </div>
                                    <div className="text-box">
                                        <h4>Harga Bersaing</h4>
                                        <div className="margin-bottom">
                                            Harga sangat kompetitif, dapat
                                            dibandingkan dengan kompetitor lain.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="12">
                                <div className="fh-align-center">
                                    <div className="icon-box">
                                        <img
                                            src={require("../assets/images/worker.png")}
                                            alt=""
                                            className="ss-img img-responsive"
                                        />
                                    </div>
                                    <div className="text-box">
                                        <h4>Pekerja Profesional</h4>
                                        <div className="margin-bottom">
                                            Tenaga kerja kami sudah profesional
                                            dan didukung dengan peralatan
                                            canggih.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                    </div>
                </div>
                <div className="fifth-section fis bg-accent">
                    <div className="section">
                        <Container className="s-container">
                            <Col lg="7" md="12">
                                <Image
                                    className="img-responsive"
                                    src={require("../assets/images/fifth-section-img.png")}
                                />
                            </Col>
                            <Col lg="5" md="12" className="alignself-center">
                                <div className="pre-title">
                                    Tukangbordir.net
                                </div>
                                <h2>
                                    Penyedia jasa Sablon dan Konveksi
                                    berkualitas di Jakarta
                                </h2>
                                <div className="margin-bottom-2"></div>
                                <Container className="s-container">
                                    <Col lg="6" md="12" className="no-padding">
                                        <span className="font-700">Sablon</span>{" "}
                                        berkualitas dengan bahan dan teknologi
                                        terbaru
                                    </Col>
                                    <Col lg="6" md="12" className="no-padding">
                                        <span className="font-700">
                                            Konveksi
                                        </span>{" "}
                                        dengan tenaga profesional yang handal
                                    </Col>
                                </Container>
                            </Col>
                        </Container>
                    </div>
                </div>
                <div className="third-section">
                    <Container className="s-container">
                        <Col lg="12" className="ts-col">
                            <div className="ts-content">
                                <div className="tsc-first">
                                    <div className="size-h2">
                                        Dapatkan pelayanan terbaik dan kualitas
                                        tinggi dari kami
                                    </div>
                                    <div>
                                        Hubungi kami untuk informasi lebih
                                        lanjut.
                                    </div>
                                </div>
                                <div className="ts-button-content">
                                    <a
                                        href="#"
                                        className="button-primary margin-right is-white"
                                    >
                                        Hubungi Kami
                                    </a>
                                    <a
                                        href="#"
                                        className="button-primary is-ghost"
                                    >
                                        Tentang Kami
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Container>
                </div>
                <div className="fourth-section fos">
                    <div className="section">
                        <Container className="s-container">
                            <Col
                                ls="5"
                                md="12"
                                className="block-centered text-align-center"
                            >
                                <h2>Kegiatan terbaru</h2>
                                <div className="text-medium">
                                    Daftar kegiatan dan informasi terbaru dari
                                    Tukangsablon.net
                                </div>
                            </Col>
                        </Container>
                        <Container className="s-container fos-blog-content">
                            {this.state.posts.map((post) => (
                                <Col lg="6" md="12" key={post.id}>
                                    <div className="blog-card fh-align-center">
                                        <a
                                            href={"/blog/post/" + post.id}
                                            className="blog-image"
                                            style={{
                                                backgroundImage:
                                                    "url(" + post.image + ")",
                                            }}
                                        ></a>
                                        <a
                                            href={"/blog/post/" + post.id}
                                            className="blog-title"
                                        >
                                            <div className="bt-text">
                                                <h3>{post.title}</h3>
                                                <div className="fh-align-center fh-space-between">
                                                    <div className="fh-align-center">
                                                        <div className="text-small">
                                                            Admin
                                                        </div>
                                                    </div>
                                                    <div className="text-small text-low-contrast">
                                                        {post.created_at.substring(
                                                            0,
                                                            10
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            ))}
                            {/* <Col lg="6" md="12">
                                <div className="blog-card fh-align-center">
                                    <a
                                        href="#"
                                        className="blog-image"
                                        style={{ backgroundImage: "" }}
                                    ></a>
                                    <a href="#" className="blog-title">
                                        <div className="bt-text">
                                            <h3>Proyek Sablon MITRATEL</h3>
                                            <div className="fh-align-center fh-space-between">
                                                <div className="fh-align-center">
                                                    <div className="text-small">
                                                        Admin
                                                    </div>
                                                </div>
                                                <div className="text-small text-low-contrast">
                                                    07 September 2020
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                         */}
                        </Container>
                        <Container className="s-container">
                            <Col lg="12" className="fh-justify-center">
                                <a
                                    href="/blog"
                                    className="button-primary ffo-blog-btn block-centered is-small"
                                >
                                    Selengkapnya
                                </a>
                            </Col>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
