import React from "react";
import "../assets/css/About.css";
import { Container, Col, Image } from "react-bootstrap";

export default class AboutComponent extends React.Component {
	render() {
		return (
			<div className="about">
				<div className="about-first">
					<Container className="s-container">
						<Col lg="7" md="12" className="block-centered text-align-center">
							<h1 className="heading-one">Tentang Kami</h1>
							<ul className="ts-breadcrumb">
								<li>
									<a href="/">Beranda</a>
								</li>
								<li className="active">Tentang Kami</li>
							</ul>
						</Col>
					</Container>
				</div>
				<div className="about-second abs">
					<div className="section">
						<Container>
							<Col lg="8" md="12" className="block-centered text-align-center">
								<p>Selamat datang</p>
								<h2>
									Tukangsablon.net bekerja secara profesional dengan didukung
									teknologi canggih
								</h2>
							</Col>
						</Container>
						<Container></Container>
					</div>
				</div>
				<div className="about-third abt bg-accent">
					<div className="section">
						<Container className="s-container">
							<Col lg="4" md="12" className="d-none d-sm-none d-md-block">
								<Image
									className="img-responsive"
									src={require("../assets/images/logo.png")}
								/>
							</Col>
							<Col lg="8" md="12" className="alignself-center">
								<h2>
									Tukangsablon.net memberikan pelayanan terbaik kepada pelanggan
								</h2>
								<div className="margin-bottom-2"></div>
								<p>
									Tukangsablon.net merupakan bagian dari{" "}
									<a href="cb18bordir.com">Cahaya Baru 18</a> yang telah berdiri
									sejak tahun 2015, berlokasi di Blok M Square, Melawai,
									Kebayoran Baru, Jakarta Selatan. Dengan pengalaman bertahun -
									tahun dan didukung oleh pekerja profesional serta teknologi
									pendukung yang canggih dan modern, kami menawakan pelayanan
									terbaik kepada setiap pelanggan. Pelanggan yang bekerja sama
									dengan kami datang dari berbagai macam kalangan, seperti rumah
									sakit, pemerintahan, BUMN dan lain sebagainya. Pelayanan
									terbaik dengan orientasi hasil yang memuaskan adalah target
									kami.
								</p>
							</Col>
							<Col lg="4" md="12" className="d-sm-block d-md-none">
								<Image
									className="img-responsive"
									src={require("../assets/images/logo.png")}
								/>
							</Col>
						</Container>
					</div>
				</div>
				<div className="about-fourth abf">
					<div className="section">
						<Container className="s-container">
							<Col lg="5" md="12" className="alignself-center">
								<div className="pre-title">Kenapa memilih kami?</div>
								<h2>Profesional dan Berpengalaman</h2>
								<p>
									Pengalaman kami dalam bidang sablon, konveksi dan bordir dapat
									menjadi alasan utama anda memilih kami. Dibuktikan dengan
									tingkat kepuasan pelanggan atas hasil yang kami berikan.
								</p>
								<ul className="check">
									<li>Pengalaman sejak 2015</li>
									<li>Pekerja Profesional</li>
									<li>Mesin Canggih</li>
									<li>Pesan Antar</li>
									<li>Pengerjaan Cepat</li>
								</ul>
							</Col>
							<Col lg="7" md="12">
								<Image
									className="img-responsive"
									src={require("../assets/images/about-why.jpg")}
								/>
							</Col>
						</Container>
					</div>
				</div>
			</div>
		);
	}
}
