import React from "react";
import { Col, Image } from "react-bootstrap";
import "../assets/css/Footer.css";

export default class FooterComponent extends React.Component {
	render() {
		return (
			<div className="footer-section foot is-dark">
				<div className="section">
					<div className="s-container flex-horizontal margin-bottom-2">
						<Col lg="3" md="12" className="fv-space-between align-left">
							<Image
								className="img-responsive foot-img d-none d-sm-block"
								src={require("../assets/images/logo.png")}
							/>
							<div className="footer-tagline margin-bottom">
								<Image
									className="img-responsive foot-img"
									src={require("../assets/images/logo-text-white.png")}
								/>
							</div>
						</Col>
						<Col lg="1" className="no-margin-bottom"></Col>
						<Col lg="8" md="12">
							<div className="text-large is-thin margin-bottom-2">
								Mulai proyek sablon dan konveksi anda dengan mudah dan cepat.
								Hubungi &nbsp;
								<a
									target="_blank"
									href="https://wa.me/628176389206"
									className="text-underline"
								>
									(+62) 817-6389-206
								</a>
							</div>
							<div className="s-container container-nested is-wrapped">
								<Col className="no-margin-bottom" lg="3" md="12">
									<div className="size-h4 margin-bottom">Alamat</div>
									<div>
										Blok M Square <br />
										Lantai Basement blok G no 35-36 <br />
										Melawai, Jakarta Selatan
									</div>
								</Col>
								<Col className="no-margin-bottom" lg="3" md="12">
									<div className="size-h4 margin-bottom">Hubungi Kami</div>
									<a
										target="_blank"
										href="https://wa.me/628176389206"
										className="text-underline"
									>
										(+62) 817-6389-206
									</a>
								</Col>
								<Col className="no-margin-bottom" lg="3" md="12">
									<div className="size-h4 margin-bottom">Navigasi</div>
									<a href="tentang" className="footer-nav-link">
										Tentang Kami
									</a>
									<a href="layanan" className="footer-nav-link">
										Layanan
									</a>
									<a href="blog" className="footer-nav-link">
										Blog
									</a>
								</Col>
								<Col className="no-margin-bottom" lg="3" md="12">
									<div className="size-h4 margin-bottom">Sosial Media</div>
									<a
										href="https://www.instagram.com/heru_nugriyanto/?hl=id"
										target="blank"
									>
										Instagram
									</a>
								</Col>
							</div>
						</Col>
					</div>
					<div className="s-container">
						<Col className="margin-bottom" lg="12">
							<div className="hr on-dark opacity"></div>
						</Col>
					</div>
					<div className="s-container">
						<Col
							className="no-margin-bottom"
							lg="12"
							md="12"
							style={{ textAlign: "center" }}
						>
							<div className="text-small">
								&copy; Tukangsablon.net, All rights reserved
							</div>
						</Col>
					</div>
				</div>
			</div>
		);
	}
}
