import React from "react";
import "../assets/css/Post.css";
import "../assets/css/Custom.css";
import { Container, Col, Image, Row } from "react-bootstrap";
import { getAPI } from "../utils/Requests";

export default class PostComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            list: [],
            show: false,
        };
    }

    componentDidMount() {
        this.getData();
        this.getList();
    }

    getData = () => {
        var url = `post/detail/${this.props.match.params.id}`;
        getAPI(url).then((data) => {
            this.setState({ posts: data });
        });
    };

    getList = () => {
        var url = "post/list?limit=3";
        getAPI(url).then((data) => {
            this.setState({ list: data });
        });
    };

    render() {
        return (
            <div className="post">
                <div
                    className="post-first"
                    style={{
                        backgroundImage: "url(" + this.state.posts.image + ")",
                    }}
                >
                    <div className="overlay"></div>
                    <Container className="s-container">
                        <Col
                            lg="12"
                            md="12"
                            className="block-centered text-align-center"
                        >
                            <h1 className="heading-one">
                                {this.state.posts.title}
                            </h1>
                            <ul className="ts-breadcrumb">
                                <li>
                                    <a href="/">Beranda</a>
                                </li>
                                <li>
                                    <a href="/blog">Blog</a>
                                </li>
                                <li className="active">
                                    {this.state.posts.title}
                                </li>
                            </ul>
                        </Col>
                    </Container>
                </div>
                <div className="post-second margin-bottom">
                    <div className="section">
                        <Container className="s-container">
                            <Col lg="12" md="12" className="block-centered">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.posts.description,
                                    }}
                                />
                            </Col>
                        </Container>
                        <Container>
                            <div className="padding-side-15">
                                <div className="author">
                                    <div className="s-container">
                                        <div className="text-small">
                                            Admin |{" "}
                                            {this.state.posts.created_at}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <Container>
                            <div className="section">
                                <div className="size-h2">Artikel lainnya</div>
                            </div>
                        </Container>
                        <Container>
                            <Row>
                                {this.state.list.map((list) => (
                                    <Col lg="4" md="12" key={list.id}>
                                        <h3 className="margin-bottom">
                                            {list.title}
                                        </h3>
                                        <div className="margin-bottom desc">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.posts
                                                        .description,
                                                }}
                                            />
                                        </div>
                                        <a
                                            href={"/blog/post/" + list.id}
                                            className="other-link"
                                        >
                                            Baca
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
