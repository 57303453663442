import React from "react";
import "../assets/css/Blog.css";
import "../assets/css/Custom.css";
import { Container, Col, Image, Row } from "react-bootstrap";
import { getAPI } from "../utils/Requests";

export default class BlogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            show: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        var url = "post/list";
        getAPI(url).then((data) => {
            this.setState({ posts: data });
        });
    };

    render() {
        return (
            <div className="blog">
                <div className="blog-first">
                    <Container className="s-container">
                        <Col
                            lg="7"
                            md="12"
                            className="block-centered text-align-center"
                        >
                            <h1 className="heading-one">Blog</h1>
                            <ul className="ts-breadcrumb">
                                <li>
                                    <a href="/">Beranda</a>
                                </li>
                                <li className="active">Blog</li>
                            </ul>
                        </Col>
                    </Container>
                </div>
                <div className="blog-second margin-bottom">
                    <div className="section">
                        <Container className="s-container">
                            <Col
                                lg="6"
                                md="12"
                                className="block-centered text-align-center"
                            >
                                <h2>Kegiatan Tukangsablon.net</h2>
                                <div>
                                    Dapatkan informasi kegiatan produksi kami
                                    dan juga info - info lainnya
                                </div>
                            </Col>
                        </Container>
                    </div>
                </div>
                <div className="blog-third">
                    <Container className="s-container fos-blog-content">
                        <Row>
                            {this.state.posts.map((post) => (
                                <Col
                                    lg="6"
                                    md="12"
                                    className="margin-bottom"
                                    key={post.id}
                                >
                                    <div className="blog-card fh-align-center">
                                        <a
                                            href={"/blog/post/" + post.id}
                                            className="blog-image"
                                            style={{
                                                backgroundImage:
                                                    "url(" + post.image + ")",
                                            }}
                                        ></a>
                                        <a
                                            href={"/blog/post/" + post.id}
                                            className="blog-title"
                                        >
                                            <div className="bt-text">
                                                <h3>{post.title}</h3>
                                                <div className="fh-align-center fh-space-between">
                                                    <div className="fh-align-center">
                                                        <div className="text-small">
                                                            Admin
                                                        </div>
                                                    </div>
                                                    <div className="text-small text-low-contrast">
                                                        {post.created_at.substring(
                                                            0,
                                                            10
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}
