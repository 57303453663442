import React from "react";
import "../assets/css/Contact.css";
import "../assets/css/Custom.css";
import { Container, Col, Image, Row } from "react-bootstrap";

export default class ContactComponent extends React.Component {
	render() {
		return (
			<div className="contact">
				<div className="contact-first">
					<Container className="s-container">
						<Col lg="7" md="12" className="block-centered text-align-center">
							<h1 className="heading-one">Hubungi Kami</h1>
							<ul className="ts-breadcrumb">
								<li>
									<a href="/">Beranda</a>
								</li>
								<li className="active">Kontak</li>
							</ul>
						</Col>
					</Container>
				</div>
				<div className="contact-second margin-bottom">
					<div className="section">
						<Container className="s-container">
							<Col lg="6">
								<h2>Tetap terhubung</h2>
								<p className="text-medium lt-contrast">
									Jangan ragu untuk menghubungi kami, isi form berikut ini untuk
									dapat terhubung dengan kami
								</p>
								<form>
									<input
										type="text"
										className="f-input-text style1 w-input"
										placeholder="Nama"
									/>
									<input
										type="text"
										className="f-input-text style1 w-input"
										placeholder="Email"
									/>
									<input
										type="text"
										className="f-input-text style1 w-input"
										placeholder="No Handphone"
									/>
									<textarea
										maxLength="5000"
										placeholder="Pesan anda"
										className="f-input-text textarea style1 w-input"
									></textarea>
									<input
										type="submit"
										value="Submit"
										data-wait="Please wait..."
										class="button-primary margin-top w-button"
									></input>
								</form>
							</Col>
							<Col lg="2" className="margin-bottom-2"></Col>
							<Col lg="4">
								<div className="size-h4 margin-bottom">Hubungi kami</div>
								<div className="margin-bottom">
									Blok M Square,
									<br />
									Lantai Basement Blok G No 35-36,
									<br />
									Melawai,
									<br />
									Jakarta Selatan - DKI Jakarta
								</div>
								<div className="margin-bottom-2">
									Whatsapp:{" "}
									<a
										target="_blank"
										href="https://wa.me/628176389206"
										className="text-underline"
									>
										(+62) 817-6389-206
									</a>
									<br />
									Buka Setiap Hari
									<br />
									10.00 - 21.00
								</div>
							</Col>
						</Container>
					</div>
				</div>
				<div className="contact-third">
					<div className="bg-accent">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1452783986647!2d106.7984859148682!3d-6.24457769547987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1ebfd6daa4d%3A0x8f10b22d03d7c9db!2sBlok%20M%20Square!5e0!3m2!1sen!2sid!4v1605898206247!5m2!1sen!2sid"
							frameBorder="none"
							style={{ width: "100%", height: "400px" }}
						></iframe>
					</div>
				</div>
			</div>
		);
	}
}
