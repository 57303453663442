import React from "react";
import "./App.css";
import "./assets/css/Custom.css";
import "bootstrap/dist/css/bootstrap.min.css";

import NavbarComponent from "./components/NavbarComponent";
import HomeComponent from "./components/HomeComponent";
import { BrowserRouter, Route } from "react-router-dom";
import Switch from "react-bootstrap/esm/Switch";
import AboutComponent from "./components/AboutComponent";
import ServiceComponent from "./components/ServiceComponent";
import BlogComponent from "./components/BlogComponent";
import PostComponent from "./components/PostComponent";
import ContactComponent from "./components/ContactComponent";
import FooterComponent from "./components/FooterComponent";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavbarComponent />
        <Switch>
          <Route exact path="/" component={HomeComponent} />
          <Route path="/tentang" component={AboutComponent} />
          <Route path="/layanan" component={ServiceComponent} />
          <Route exact path="/blog" component={BlogComponent} />
          <Route path="/blog/post/:id" component={PostComponent} />
          <Route path="/kontak" component={ContactComponent} />
        </Switch>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
